import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ['visibility']

  static classes = ['hidden']

  toggleVisibility () {
    this.visibilityTargets.forEach((target) => {
      if (target.classList.contains(this.hiddenClass)) {
        target.classList.remove(this.hiddenClass)
      } else {
        target.classList.add(this.hiddenClass)
      }
    })
  }
}
