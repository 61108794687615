import BaseController from './base_controller'
import { debounce } from 'lodash'

// This class submit a date form
// Can support debouncing if needed
export default class extends BaseController {
  static values = {
    formId: String,
    debounceForm: { type: Boolean, default: true }, // set up debouncer for form so it doesn't submit automatically
    debounceTime: { type: Number, default: 500 } // set up the default delay for debouncer
  }

  initialize () {
    if (this.debounceFormValue) {
      this.submitClearedForm = debounce(this.submitClearedForm, this.debounceTimeValue)
    }
  }

  /**
   * Only submit the form once an input has been cleared
   * @param {EventObject} event
   */
  submitClearedForm (event) {
    const targetedForm = document.getElementById(this.formIdValue)

    if (this.validDate(event)) { targetedForm.submit() }
  }

  /**
   * Checks to see if the Date is a valid one before submitting
   * 1. If there is no date provided (i.e the date field was cleared)
   * 2. Is a valid date
   * 3. Date.parse returns a positive number (date entered by user comes after 1970)
   * @param {EventObject} event
   * @return {Boolean}
   */
  validDate (event) {
    if (event.target.value.length === 0) { return true }

    return !Number.isNaN(Date.parse(event.target.value)) && Date.parse(event.target.value) > 0
  }
}
