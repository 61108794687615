import BaseController from './base_controller'

// This class is controlling Header Banner Component
export default class extends BaseController {
  static targets = ['banner', 'dismissButton']
  static classes = ['animIn']

  // Dismiss Header Banner
  dismiss () {
    this.bannerTarget.dispatchEvent(new CustomEvent('dismissHeaderBanner'))
    this.bannerTarget.classList.remove(this.animInClass)
    this.bannerTarget.remove()
  }
}
