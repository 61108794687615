import BaseController from './base_controller'

// This class is controlling Header Component
export default class extends BaseController {
  static targets = ['header', 'banner']
  static classes = ['hasBanner']

  // When connecting bannerTarget, which has at least one bannerTarget exists
  bannerTargetConnected () {
    this.headerTarget.classList.add(this.hasBannerClass)
  }

  // When disconnecting bannerTarget, which no bannerTarget exists
  bannerTargetDisconnected () {
    this.headerTarget.classList.remove(this.hasBannerClass)
  }

  // Remove hasBannerClass at headerTargets
  removeHasBannerClass () {
    this.headerTarget.classList.remove(this.hasBannerClass)
  }
}
