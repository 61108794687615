// Entry point for the build script in your package.json
import '../controllers'

import '@hotwired/turbo-rails'

// Import Tippy
import 'tippy.js'

// Setup Sentry
import '../lib/sentry'

// Import Font Awesome
require('@fortawesome/fontawesome-free/js/all.js')
