import BaseController from './base_controller'
import { fire } from './../lib/compatibility'
import { debounce } from 'lodash'

// This class submit a targeted form from outside of the form element
// Can support debouncing if needed
export default class extends BaseController {
  static values = {
    formId: String,
    fieldHasValue: { type: Boolean, default: false }, // make sure we don't submit when no search has been done yet
    debounceForm: { type: Boolean, default: true }, // set up debouncer for form so it doesn't submit automatically
    debounceTime: { type: Number, default: 500 } // set up the default delay for debouncer
  }

  initialize () {
    if (this.debounceFormValue) {
      this.submitForm = debounce(this.submitForm, this.debounceTimeValue)
      this.submitRemoteForm = debounce(this.submitRemoteForm, this.debounceTimeValue)
    }
  }

  submitForm () {
    const targetedForm = document.getElementById(this.formIdValue)
    targetedForm.submit()
  }

  /**
   * Only submit the form once an input has been cleared
   * (IE: clicking on the built in 'X' in the input field)
   * NOTE: clicking the 'X' will trigger a 'search' event (NOT a 'change' event)
   * Ref: https://www.freecodecamp.org/news/targeting-click-of-clear-button-x-on-input-field/
   * @param {EventObject} event
   */
  submitClearedForm (event) {
    if (this.clearedFormConditions(event)) {
      this.submitForm()
    }
  }

  /**
   * Submitting a form with a remote request
   */
  submitRemoteForm () {
    const targetedForm = document.getElementById(this.formIdValue)
    fire(targetedForm, 'submit')
  }

  /**
   * Only submit the form once an input has been cleared
   * (IE: clicking on the built in 'X' in the input field)
   * NOTE: clicking the 'X' will trigger a 'search' event (NOT a 'change' event)
   * Ref: https://www.freecodecamp.org/news/targeting-click-of-clear-button-x-on-input-field/
   * @param {EventObject} event
   */
  submitClearedRemoteForm (event) {
    if (this.clearedFormConditions(event)) {
      this.submitRemoteForm()
    }
  }

  /**
   * Conditions for submitting a "cleared" form
   * 1. the value has been emptied
   * 2. there are results on the page (ie: don't sumbit if there were no results previously)
   * @param {EventObject} event
   * #@return {Boolean}
   */
  clearedFormConditions (event) {
    return event.target.value.length === 0 && this.fieldHasValueValue
  }
}
