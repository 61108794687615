import BaseController from './base_controller'

export default class FileFormController extends BaseController {
  static targets = ['fileInput', 'submitSection', 'fileName']
  static classes = ['hidden', 'flex']

  fileSelected () {
    if (this.fileInputTarget.value != null) {
      this.submitSectionTarget.classList.replace(this.hiddenClass, this.flexClass)

      this.fileInputTarget.classList.add(this.hiddenClass)

      this.fileNameTarget.innerHTML = this.cleanDisplayFilePath(this.fileInputTarget.value)
    }
  }

  clearFileForm () {
    this.submitSectionTarget.classList.replace(this.flexClass, this.hiddenClass)

    this.fileInputTarget.classList.remove(this.hiddenClass)
    this.fileInputTarget.value = ''
  }

  /**
   * Clean up the file path so it only shows the file name
   * @param {String} path - file path displayed in the input
   * @return {String} cleaned up file path string to display
   */
  cleanDisplayFilePath (path) {
    return path.split('\\').pop()
  }
}
