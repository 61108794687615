import BaseController from './base_controller'

export default class CollapsibleController extends BaseController {
  static classes = ['collapsed', 'expanded', 'hidden']
  static targets = ['button', 'collapsible']

  collapse () {
    this.buttonTarget.classList.remove(this.expandedClass)
    this.buttonTarget.classList.add(this.collapsedClass)
    this.collapsibleTarget.classList.add(this.hiddenClass)
  }

  expand () {
    this.buttonTarget.classList.remove(this.collapsedClass)
    this.buttonTarget.classList.add(this.expandedClass)
    this.collapsibleTarget.classList.remove(this.hiddenClass)
  }

  toggle () {
    if (this.buttonTarget.classList.contains(this.collapsedClass)) {
      this.expand()
    } else {
      this.collapse()
    }
  }
}
